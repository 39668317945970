import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img } from '@raketa-cms/raketa-cms';
import Button from '../frontend/Button';

const SpotlightImage = ({ image, direction, imageCredits }) => (
  <div className="image-col half">
    <Img src={image} variant="spotlight" />

    {imageCredits &&
      <div className={`credits ${direction === 'right' ? 'right' : ''}`}>
        <span className="credits-text">{imageCredits}</span>
      </div>
    }
  </div>
);

const SpotlightContent = ({ title, description, primaryButton }) => (
  <div className="half">
    <div className="spotlight-content-wrapper">
      <div className="spotlight-content">
      <span className="divider"></span>

        <h4>{title}</h4>

        {description &&
          <div dangerouslySetInnerHTML={{ __html: description }} />
        }


          {(primaryButton && primaryButton.label) &&
            <div className="btn-wrapper">
              <Button settings={primaryButton} />
            </div>
          }
      </div>
    </div>
  </div>
);

const SpotlightWidget = ({
  direction,
  image,
  imageCredits,
  title,
  description,
  primaryButton,
  containerSettings
}) => (
  <Container settings={containerSettings}>
      <div className="spotlight">
        <div className="container">
          {direction === 'left' &&
            <div className="grid">
              <SpotlightImage image={image} imageCredits={imageCredits} direction={direction} />

              <SpotlightContent
                title={title}
                description={description}
                primaryButton={primaryButton}
              />
            </div>
          }

          {direction === 'right' &&
            <div className="grid">
              <SpotlightContent
                title={title}
                description={description}
                primaryButton={primaryButton}
              />

              <SpotlightImage image={image} imageCredits={imageCredits} direction={direction} />
            </div>
          }
        </div>
      </div>
  </Container>
);

SpotlightWidget.title = 'Spotlight';
SpotlightWidget.category = 'Highlights';

SpotlightWidget.dialogSize = 'large';

SpotlightWidget.defaults = {
  direction: 'left',
  image: 'http://via.placeholder.com/540x454',
  imageCredits: '',
  title: 'Spotlight title',
  description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic voluptatibus ratione ab nisi facere voluptas unde recusandae, dolorem quis blanditiis odit similique voluptate. Nostrum praesentium expedita vero, culpa eum perferendis!</p>',
  primaryButton: { type: 'primary', label: 'Learn more', link: '#' },
};

SpotlightWidget.adminFields = {
  direction: { type: 'select', options: [['left', 'Left'], ['right', 'Right']] },
  image: { type: 'image' },
  imageCredits: { type: 'text' },
  title: { type: 'text' },
  description: { type: 'rich' },
  primaryButton: { type: 'button' }
};

export default SpotlightWidget;
