import React from 'react';
import PropTypes from 'prop-types';
import { Container, List, Img, RichText } from '@raketa-cms/raketa-cms';
import Button from '../frontend/Button';

const Content = ({ text }) => (
  <div className="eight">
    <div className="text-content-wrapper">
      <div className="text-content">
        {text &&
          <div dangerouslySetInnerHTML={{ __html: text }} />
        }
      </div>
    </div>
  </div>
);

const Sidebar = ({ list }) => (
  <div className="third">
    <div className="sidebar-content-wrapper">
      <div className="sidebar-content">
        {list.map((text, idx) => (
          <div key={idx} className="sidebar-content-item">
            {text.content &&
              <div dangerouslySetInnerHTML={{ __html: text.content }} />
            }
          </div>
        ))}
      </div>
    </div>
  </div>
);

const TextAndSidebarWidget = ({
  text,
  list,
  containerSettings
}) => (
  <Container settings={containerSettings}>
      <div className="text-and-sidebar">
        <div className="container">
          <div className="grid">
            <Content
              text={text}
            />

            <Sidebar list={list}/>
          </div>
        </div>
      </div>
  </Container>
);

TextAndSidebarWidget.title = 'Text + Sidebar';
TextAndSidebarWidget.category = 'Highlights';

TextAndSidebarWidget.dialogSize = 'large';

TextAndSidebarWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

TextAndSidebarWidget.defaults = {
  text: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic voluptatibus ratione ab nisi facere voluptas unde recusandae, dolorem quis blanditiis odit similique voluptate. Nostrum praesentium expedita vero, culpa eum perferendis!</p>'
  + '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>'
  + '<ul><li><a href="">A woman\'s health status [link to section]</a></li><li>A woman\'s health status [link to section]</li></ul>',
  list: [
    { id: 1, content: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic voluptatibus ratione ab nisi facere voluptas unde recusandae, dolorem quis blanditiis odit similique voluptate. Nostrum praesentium expedita vero, culpa eum perferendis!</p>'},
    { id: 2, content: '<a href="#">Example</a>'},
 ],
};

const SidebarItem = ({ settings, onChangeItem }) => (
  <div>
    <RichText
      label="Content"
      onChange={value => onChangeItem('content', value)}
      value={settings.content}
    />
  </div>
);

SidebarItem.defaultProps = {
  settings: {},
};

SidebarItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

TextAndSidebarWidget.adminFields = (items, onChange, settings) => (
  <div>
     <RichText
      label="Text"
      value={settings.text}
      onChange={value => onChange('text', value)}  
    />

    <List
      listItem={(settings, onChangeItem) =>
        <SidebarItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default TextAndSidebarWidget;
