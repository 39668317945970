import SectionTitleWidget from './SectionTitleWidget';
import TextBoxWidget from './TextBoxWidget';
import TextBoxTwoColsWidget from './TextBoxTwoColsWidget';
import TextAndImageWidget from './TextAndImageWidget';
import TextAndSidebarWidget from './TextAndSidebarWidget';
import SpotlightAndQuoteWidget from './SpotlightAndQuoteWidget';
import SocialCTAWidget from './SocialCTAWidget';
import CallToActionWidget from './CtaWidget';
import SpotlightWidget from './SpotlightWidget';
import ArticleListWidget from './ArticleListWidget';
import StoryHeaderWidget from './StoryHeaderWidget';
import LeadImageWidget from './LeadImageWidget';
import BeforeAndAfterWidget from './BeforeAndAfterWidget';
import HtmlWidget from './HtmlWidget';
import ImageWidget from './ImageWidget';
// import StatsWidget from './StatsWidget';
// import SliderWidget from './SliderWidget';
// import SingleImageWidget from './SingleImageWidget';
// import CardsWidget from './CardsWidget';

export default {
  SectionTitleWidget,
  TextBoxWidget,
  TextBoxTwoColsWidget,
  TextAndImageWidget,
  TextAndSidebarWidget,
  SpotlightAndQuoteWidget,
  SocialCTAWidget,
  CallToActionWidget,
  SpotlightWidget,
  ArticleListWidget,
  StoryHeaderWidget,
  HtmlWidget,
  LeadImageWidget,
  BeforeAndAfterWidget,
  ImageWidget
  // StatsWidget,
  // SliderWidget,
  // SingleImageWidget,
  // CardsWidget
};
