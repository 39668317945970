import React from 'react';
import { ListInput, TextInput } from '@raketa-cms/raketa-cms';

const MenuItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Label"
      onChange={value => onChangeItem('label', value)}
      value={settings.label}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

class MenuEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.value.length > 0 ? props.value : [{id: 1, link: '#', label: 'Link 1'}],
    };
  }

  render() {
    const { name } = this.props;
    const { items } = this.state;

    return (
      <div className="form-group">
        <label className="control-label">Menu items</label>
        <ListInput
          listItem={(settings, onChangeItem) =>
            <MenuItem settings={settings} onChangeItem={onChangeItem} />}
          onChangeList={(_, items) => this.setState({ items })}
          items={items}
        />

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    );
  }
}

export default MenuEditor;
