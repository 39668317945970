import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, imageSrc } from '@raketa-cms/raketa-cms';
import Button from '../frontend/Button';
import SvgTitle from '../frontend/SvgTitle';

const LeadImageWidget = ({ imageDesktop, imageMobile, title, description, primaryButton, secondaryButton, overlay, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="lead-image">
      <div className="background-image" style={{ backgroundImage: `url('${imageSrc(imageDesktop, 'lead_image')}')` }} />

      <div className="content">
        <div className="container">
          <div className="grid">
            <div className="ninth">
              <div className="lead-image-content lead-image-description">
                <h1><SvgTitle settings={title}/></h1>
                <div dangerouslySetInnerHTML={{ __html: description }} />

                {(primaryButton && primaryButton.label) &&
                  <div className="btn-wrapper">
                    <Button settings={primaryButton} />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadImageWidget.title = 'Lead Image';
LeadImageWidget.category = 'Media';

LeadImageWidget.defaults = {
  imageDesktop: 'http://placehold.it/1920x684',
  imageMobile: 'http://placehold.it/1000x800',
  title: 'It is about time we give women a true voice',
  description: '<p>Every year, there are 210 million pregnancies in the world. Each has its own story, but one fact unites them all: How women experience their pregnancy has long-term consequences not only for the women themselves, but also for their children, families and communities.'
  + 'To express their personal experiences of pregnancy and childbirth, and what this means for their quality of life - physically, mentally, socially, financially, maternally and sexually. '
  + 'Because</p><p>Because <strong>a positive pregnancy experience is a woman\‘s right</strong></p><p>And <strong>every woman’s story counts</strong></p>',
  primaryButton: { type: 'primary', label: 'Let us show you how', link: '#' },
  containerSettings: {},
};

LeadImageWidget.adminFields = {
  imageDesktop: { type: 'image' },
  imageMobile: { type: 'image' },
  title: { type: 'text' },
  description: { type: 'rich' },
  primaryButton: { type: 'button' }
};

export default LeadImageWidget;
