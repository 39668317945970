import React from 'react';
import { Container, Img, imageSrc } from '@raketa-cms/raketa-cms';
import Button from '../frontend/Button';
import SvgTitle from '../frontend/SvgTitle';

const CallToActionWidget = ({ image, title, description, primaryButton, sideBarImage, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cta-widget">
      <div className="content">
        <div className="container">
          <div className="grid">
            <div className="shift-1 content-inner">
              <div className="half cta-inner" style={{ backgroundImage: `url('${imageSrc(image)}')` }}>
                <h4><SvgTitle settings={title}/></h4>
                {description &&
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                }

                <div className="btn-wrapper">
                {(primaryButton && primaryButton.label) &&
                  <Button settings={primaryButton} />
                }
                </div>
              </div>

              {sideBarImage &&
                <div className="third image-wrapper">
                  <Img src={sideBarImage} variant="sidebar" />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

CallToActionWidget.title = 'Call to Action';
CallToActionWidget.category = 'Highlights';

CallToActionWidget.defaults = {
  variant: 'horizontal',
  image: 'http://placehold.it/1920x596',
  sideBarImage: 'http://placehold.it/350x432',
  title: 'Giving women a  VOICE  to uncover the burden of maternal morbidity',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ',
  primaryButton: { type: 'primary', label: 'Primary', link: '#' },
  containerSettings: {},
};

CallToActionWidget.adminFields = {
  image: { type: 'image' },
  title: { type: 'text' },
  description: { type: 'rich' },
  primaryButton: { type: 'button' },
  sideBarImage: { type: 'image' },
};

export default CallToActionWidget;