import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, imageSrc } from '@raketa-cms/raketa-cms';

const BeforeAndAfterWidget = ({ beforeImage, afterImage, from, to, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="before-and-after">

      {/* <div className="overlay" style={{ background: `rgba(0, 0, 0, ${overlay})` }}></div> */}

      <div className="content">
        <div className="container">
          <div className="grid">
            <div className="full-width">
              <div className="before-and-after-wrapper">
                <Img src={beforeImage} variant="before_image" className="before-image" />

                <div className="before-and-after-content box-shadow">
                  <div className="from"><h3>{from}</h3></div>

                  <div className="to"><h3>{to}</h3></div>
                </div>

                <Img src={afterImage} variant="after_image" className="after-image box-shadow"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

BeforeAndAfterWidget.title = 'Before/After';
BeforeAndAfterWidget.category = 'Media';

BeforeAndAfterWidget.defaults = {
  beforeImage: 'http://placehold.it/635x424',
  afterImage: 'http://placehold.it/825x550',
  from: 'FROM SIMPLY SURVIVING',
  to: 'TO TRULY THRIVING',
  containerSettings: {},
};

BeforeAndAfterWidget.adminFields = {
  beforeImage: { type: 'image' },
  afterImage: { type: 'image' },
  from: { type: 'text' },
  to: { type: 'text' },
};

export default BeforeAndAfterWidget;
