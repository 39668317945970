import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, imageSrc } from '@raketa-cms/raketa-cms';

const StoryHeaderWidget = ({ imageDesktop, imageMobile, from, to, title, content, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="lead-image story-header">
      <div className="background-image" style={{ backgroundImage: `url('${imageSrc(imageDesktop, 'lead_image')}')` }} />
        <div className="content">
          <div className="container">
            <div className="grid">
              <div className="ninth">
                <div className="lead-image-content story-header-content">
                  <div className="story-header-content-from-to">
                    <div className="story-header-content-from">
                      <div className="label">From</div>

                      <span>{from}</span>
                    </div>

                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMjAgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0i8J+Nki1JbnRlcmZhY2UiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlN0b3J5LTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNzQuMDAwMDAwLCAtNTE4LjAwMDAwMCkiIGZpbGw9IiMxRjIzM0YiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPGcgaWQ9Ikhlcm8iPiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTY1LjAwMDAwMCwgNTA0LjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIyOSwyMC45NDQ0NDQ0IEwyMjguNTk5Nzk1LDIxLjM2MTU0MzQgTDIyMi4yNTEwOTQsMjcuNjcyNDMxOSBDMjIyLjExMDExMywyNy44NDQ3MTE5IDIyMS44ODQ5OTgsMjcuOTI0MDUxNSAyMjEuNjY2NzA1LDI3Ljg3NDE4MDcgQzIyMS40NTA2ODUsMjcuODI0MzEwNCAyMjEuMjgwMTQzLDI3LjY1NDI5NzEgMjIxLjIzMDExOCwyNy40Mzg5NDcgQzIyMS4xODAwOTIsMjcuMjIxMzMwMSAyMjEuMjU5Njc4LDI2Ljk5NjkxMzIgMjIxLjQzMjQ5NCwyNi44NTYzNjg3IEwyMjYuNzgwNjgzLDIxLjUyNDc1NiBMMjA5LjU5MDA3NSwyMS41MjQ3NTYgQzIwOS4zODA4NzcsMjEuNTI3MDIyNyAyMDkuMTg1MzIyLDIxLjQxODIxNDMgMjA5LjA3ODQ0OSwyMS4yMzY4NjY5IEMyMDguOTczODUsMjEuMDU1NTE5NiAyMDguOTczODUsMjAuODMzMzY5MyAyMDkuMDc4NDQ5LDIwLjY1MjAyMiBDMjA5LjE4NTMyMiwyMC40NzA2NzQ2IDIwOS4zODA4NzcsMjAuMzYxODY2MiAyMDkuNTkwMDc1LDIwLjM2NDEzMjkgTDIyNi43ODA2ODMsMjAuMzY0MTMyOSBMMjIxLjQzMjQ5NCwxNS4wMzI1MjAyIEMyMjEuMjU5Njc4LDE0Ljg5MTk3NjMgMjIxLjE4MDA5MiwxNC42Njc1NTg3IDIyMS4yMzAxMTgsMTQuNDQ5OTQxOSBDMjIxLjI4MDE0MywxNC4yMzQ1OTE3IDIyMS40NTA2ODUsMTQuMDY0NTc4NCAyMjEuNjY2NzA1LDE0LjAxNDcwODIgQzIyMS44ODQ5OTgsMTMuOTY0ODM3NCAyMjIuMTEwMTEzLDE0LjA0NDE3NyAyMjIuMjUxMDk0LDE0LjIxNjQ1NyBMMjI4LjU5OTc5NSwyMC41MjczNDU1IEwyMjksMjAuOTQ0NDQ0NCBaIiBpZD0iU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==" alt="Voice logo" className="icon-arrow" width="20px" height="14px"/>

                    <div className="story-header-content-to">
                      <div className="label">To</div>

                      <span>{to}</span>
                    </div>
                  </div>

                  <h2>{title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Container>
);

StoryHeaderWidget.title = 'Story header';
StoryHeaderWidget.category = 'Media';

StoryHeaderWidget.defaults = {
  imageDesktop: 'http://placehold.it/1920x810',
  imageMobile: 'http://placehold.it/1000x800',
  from: 'Focus on morality',
  to: 'THE MAGNITUDE OF MORBIDITIES',
  title: 'WOMEN DESERVE MORE THAN TO SIMPLY SURVIVE A PREGNANCY',
  content: '<p>Raising the bar for what maternal health really means. </p>'
  + '<p>Efforts to improve maternal health on a global level has traditionally been focused on preventing women from dying during pregnancy and childbirth. However, maternal mortality only constitutes a small fraction of the women’s burdens related to pregnancy and childbirth.</p>',
  containerSettings: {},
};

StoryHeaderWidget.adminFields = {
  imageDesktop: { type: 'image' },
  imageMobile: { type: 'image' },
  from: { type: 'text' },
  to: { type: 'text' },
  title: { type: 'text' },
  content: { type: 'rich' }
};

export default StoryHeaderWidget;
