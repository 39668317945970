import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, TextInput, ImagePicker, RichText } from '@raketa-cms/raketa-cms';

const TextBoxTwoColsWidget = ({ firstDescription, secondDescription, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="text-boxes">
      <div className="container">
        <div className="grid">       
            <div className="half">
               <div className="text-box">
                  <div className="text-box-content">
                    {firstDescription &&
                      <div className="text" dangerouslySetInnerHTML={{ __html: firstDescription }} />
                    }
                  </div>
                </div>
            </div>

             <div className="half">
               <div className="text-box">
                  <div className="text-box-content">
                    {secondDescription &&
                      <div className="text" dangerouslySetInnerHTML={{ __html: secondDescription }} />
                    }
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </Container>
);

TextBoxTwoColsWidget.title = 'Text (2 columns)';
TextBoxTwoColsWidget.category = 'Lists';

TextBoxTwoColsWidget.dialogSize = 'large';

TextBoxTwoColsWidget.defaults = {
  firstDescription: '<h4>A woman\'s health status</h4>'
    + '<p>There are many underlying and contributing health conditions that a woman may have at the time of conception and pregnancy,'
    + 'and that may have at the time of conception and pregnancy, and that influence the outcome of a pregnancy. These include:</p>'
    + '<ul><li>Violence</li><li>Where a woman is satisfied with her secual life</li></ul>',
  secondDescription: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ',
  containerSettings: {},
};

TextBoxTwoColsWidget.adminFields = {
  firstDescription: { type: 'rich' },
  secondDescription: { type: 'rich' },
};

export default TextBoxTwoColsWidget;
