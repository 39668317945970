import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, List, TextInput, ImagePicker, RichText } from '@raketa-cms/raketa-cms';
import SvgTitle from '../frontend/SvgTitle';

const ArticleListWidget = ({ title, cta, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="articles">
      <div className="container">
        <div className="grid articles-header">
          <div className="eight">
            <h3><SvgTitle settings={title}/></h3>
          </div>

          <div className="third">
            {cta &&
              <div className="cta-block" dangerouslySetInnerHTML={{ __html: cta }} />
            }
          </div>

        </div>
        <div className="grid">
          {list.map((article, idx) => (
            <div className="third" key={idx}>
              { article.link ?
                <a href={article.link} className="article-image">
                  <div className="article">
                    <div className="image-wrapper">
                      <Img src={article.image} variant="article_list" />
                    </div>

                    <div className="article-content">
                      {article.description &&
                        <div dangerouslySetInnerHTML={{ __html: article.description }} />
                      }

                      <span className="meta">{article.meta}</span>
                    </div>
                  </div>
                </a>
                :
                <div className="article-image">
                  <div className="article">
                    <div className="image-wrapper">
                      <Img src={article.image} variant="article_list" />
                    </div>

                    <div className="article-content">
                      {article.description &&
                        <div dangerouslySetInnerHTML={{ __html: article.description }} />
                      }

                      <span className="meta">{article.meta}</span>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

ArticleListWidget.title = 'Article list';
ArticleListWidget.category = 'Lists';

ArticleListWidget.dialogSize = 'large';

ArticleListWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ArticleListWidget.defaults = {
  title: 'Article list title',
  cta: 'Have a story? <a href="/">Share it here</a>',
  list: [
    { id: 1, image: 'http://via.placeholder.com/350x170', title: 'Article title', description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, necessitatibus repellat soluta quis numquam.</p>', meta: 'Lorem, ipsum, dolar'},
    { id: 2, image: 'http://via.placeholder.com/350x170', title: 'Article title', description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, necessitatibus repellat soluta quis numquam.</p>', meta: 'Lorem, ipsum, dolar', link: '#' },
    { id: 3, image: 'http://via.placeholder.com/350x170', title: 'Article title', description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, necessitatibus repellat soluta quis numquam.</p>', meta: 'Lorem, ipsum, dolar', link: '#' }
  ]
};

const ArticleItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />

    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <RichText
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />

    <TextInput
      label="Meta"
      onChange={value => onChangeItem('meta', value)}
      value={settings.meta}
    />
  </div>
);

ArticleItem.defaultProps = {
  settings: {},
};

ArticleItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

ArticleListWidget.adminFields = (items, onChange, settings) => (
  <div>
    <RichText
      label="Title"
      onChange={value => onChange('title', value)}
      value={settings.title}

    />

    <RichText
      label="Cta"
      onChange={value => onChange('cta', value)}
      value={settings.cta}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <ArticleItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default ArticleListWidget;
