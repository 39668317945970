import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img } from '@raketa-cms/raketa-cms';
import Button from '../frontend/Button';

const Image = ({ image }) => (
  <div className="third">
    <Img src={image} variant="sidebar" />
  </div>
);

const Content = ({ title, description }) => (
  <div className="eight">
    <div className="text-and-image-content-wrapper">
      <div className="text-and-image-content">
        <h2>{title}</h2>
        {description &&
          <div dangerouslySetInnerHTML={{ __html: description }} />
        }
      </div>
    </div>
  </div>
);

const TextAndImageWidget = ({
  title,
  description,
  image,
  containerSettings
}) => (
  <Container settings={containerSettings}>
      <div className="text-and-image">
        <div className="container">
          <div className="grid">
            <Content
              title={title}
              description={description}
            />

            <Image image={image} />
          </div>
        </div>
      </div>
  </Container>
);

TextAndImageWidget.title = 'Text + Image';
TextAndImageWidget.category = 'Highlights';

TextAndImageWidget.dialogSize = 'large';

// TextAndImageWidget.defaults = {
//   image: 'http://via.placeholder.com/420x488',
//   title: 'Content title',
//   description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic voluptatibus ratione ab nisi facere voluptas unde recusandae, dolorem quis blanditiis odit similique voluptate. Nostrum praesentium expedita vero, culpa eum perferendis!</p>',
// };

TextAndImageWidget.defaults = {
  image: 'http://via.placeholder.com/420x488',
  title: 'Bad health should not be accepted as the norm',
  description: '<p>The traditional view on maternal morbidity has had a narrow focus on life-threatening conditions. '
  +'This has meant that many women, who have suffered less severe morbidities, have had their pregnancy experiences '
  + 'largely ignored in discussions about maternal health.</p>'
  + '<blockquote>Too often non life threatening conditions are not considered “real medical problems”</blockquote>',
};

TextAndImageWidget.adminFields = {
  title: { type: 'text' },
  description: { type: 'rich' },
  image: { type: 'image' },
};

export default TextAndImageWidget;
