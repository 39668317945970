import React from 'react';
import PropTypes from 'prop-types';

import LIBRARY from './widgets';
import Header from './frontend/Header'
import Footer from './frontend/Footer'

const PageRender = ({ page }) => (
  <div>
    <Header />

    {page.widgets.map((widget, idx) =>
      React.createElement(
        LIBRARY[widget.component],
        Object.assign({}, { key: idx }, widget.settings)))}

    <Footer />
  </div>
);

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
